<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t(dateTitle) }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-menu 
                                        v-model="dateMenuVisible"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="exportDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                                clearable
                                                @click:clear="exportDate= null"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker 
                                            v-model="exportDate"
                                            @input="dateMenuVisible = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="isRegionVisible">
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <!--<div class="onlyReadData"></div>-->
                                    <v-autocomplete
                                        :items="regions"
                                        v-model="exportRegion"
                                        item-text="name"
                                        item-value="id"
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :readonly="regions.length == 1"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="valid"
                        color="cyan" 
                        text 
                        depressed
                        @click="ok" 
                        >
                        {{ $t("Сформировать") }}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{ $t("Отмена") }}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import store from '@/store';
import sys from '@/services/system';

export default {
    name: "SelectExportParamsDlg",
    asyncComputed: {
        regions: {
            async get() {
                
                let regions = await store.dispatch('saylau/references/fetchRegions');                
                let arr = [];
                if (regions.length > 1)
                    arr.push({ id: null, name: "Все" });
                return arr.concat(regions);
            },
            default: []
        },
    },
    computed: {
        valid() {
            return this.exportDate != null;
        },
        isRegionVisible() {
            return this.exportType != 0;
        },
        title() {
            return this.exportType == 5 ? "Параметры_экспорта" : "Параметры_отчета";
        },
        dateTitle() {
            return this.exportType == 5 ? "Экспорт_на" : "Отчет_на";
        }
    },
    data () {
        return {
            visible: false,            
            resolve: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            exportType: null,

            exportDate: null,
            exportRegion: null,

            dateMenuVisible: false
        }
    },
    methods: {
        async open({ exportType }) {
            this.exportDate = this.$moment().format('YYYY-MM-DD');
            if (this.regions.length == 1)
                this.exportRegion = this.regions[0].id;
            else 
                this.exportRegion = null;
            
            this.visible = true;
            this.exportType = exportType;
            
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        ok() {
            this.visible = false;
            this.dateMenuVisible = false;
            this.resolve({ Type: this.exportType, ExportDate: this.exportDate, Region: this.exportRegion });
        },
        cancel() {
            this.visible = false;
            this.dateMenuVisible = false;
            this.resolve({ isCancelled: true, message: "Действие_отменено" }); 
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
}
</script>