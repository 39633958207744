<template>
    <div class="p-relative">

        <div class="top-btns-bar">
            <div class="transparent-buttons">
                <v-btn small tile text @click="ExportList" :disabled="loading">
                    <v-icon left>
                        far fa-file-chart-line
                    </v-icon>
                    {{ $t('Экспорт_списка') }}
                </v-btn>
                <v-btn small tile text @click="Refresh" :disabled="loading">
                    <v-icon left>far fa-sync-alt</v-icon>
                    {{ $t("Обновить") }}
                </v-btn>
            </div>
        </div>

        <v-data-table
            class="elevation-1 customization-list-table"
            :headers="headers"
            :items="entities"
            :items-per-page="total"
            :loading="loading"
            hide-default-footer
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.name`]="{ item, index }">
                <div :class="{ 'font-weight-bold': index === (entities.length - 1) }">
                    {{ item.name }}
                </div>
            </template>

            <template v-slot:[`item.electionParts`]="{ item, index }">
                <div :class="{ 'font-weight-bold': index === (entities.length - 1) }">
                    {{ item.electionParts }}
                </div>
            </template>

            <template v-slot:[`item.residents`]="{ item }">
                <v-chip
                    class="table-label"
                    small
                    label
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.residents }}</div>
                </v-chip>
            </template>

            <template v-slot:[`item.residentsOnDistribution`]="{ item }">
                <v-chip
                    class="table-label"
                    small
                    label
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.residentsOnDistribution }}</div>
                </v-chip>
            </template>

            <template v-slot:[`item.residentsOutDistribution`]="{ item }">
                <v-chip
                    class="table-label"
                    small
                    label
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.residentsOutDistribution }}</div>
                </v-chip>
            </template>

            <template v-slot:[`item.rowSum`]="{ item }">
                <v-chip
                    class="table-label"
                    small
                    label
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.residents + item.residentsOnDistribution + item.residentsOutDistribution }}</div>
                </v-chip>
            </template>

        </v-data-table>

        <SelectExportParamsDlg ref="SelectExportParamsDlgRef" />

    </div>
</template>

<script>
import i18n from '@/i18n';
import { httpAPI, errorHandler } from "@/api/httpAPI";

import SelectExportParamsDlg from '@/components/saylau/dialogs/SelectExportParamsDlg.vue';

export default {
    name: "RepVotersByRegion",
    components: {
        SelectExportParamsDlg
    },
    computed: {
        headers() {
            return [
                { text: i18n.t("Территориальная_единица"), value: 'name', sortable: false },
                { text: i18n.t("Количество_участков"), value: 'electionParts', sortable: false, width: '200px' },
                { text: i18n.t("Количество_избирателей"), value: 'residents', sortable: false, width: '200px' },
                { text: i18n.t("Количество_лиц_без_адресов"), value: 'residentsOnDistribution', sortable: false, width: '200px' },
                { text: i18n.t("Количество_лиц_без_участков"), value: 'residentsOutDistribution', sortable: false, width: '200px' },
                { text: i18n.t("Всего_лиц"), value: 'rowSum', sortable: false, width: '200px' },
            ];
        },
    },
    data: () => {
        return {
            loading: false,
            total: 0,            
            entities: []
        }
    },
    methods: {
        async loadData() {
            this.entities = [];
            this.total = 0;
            this.loading = true;

            let response = await httpAPI.get(`saylau/report/votersbyregion`);

            if (response) {
                this.entities = response.data;
                this.entities.push(
                { 
                    name: i18n.t("ИТОГО"),                        
                    electionParts: this.sum(response.data, 'electionParts'),
                    residents: this.sum(response.data, 'residents'),
                    residentsOnDistribution: this.sum(response.data, 'residentsOnDistribution'),
                    residentsOutDistribution: this.sum(response.data, 'residentsOutDistribution')
                });
                this.total = this.entities.length;                
            }

            this.loading = false;
        },
        sum (items, prop) {
            return items.reduce( function(a, b){
                return a + b[prop];
            }, 0);
        },
        async Refresh() {
            await this.loadData();
        },
        async Export() {
            let onResolve = (response) => {

                let filename = "Export.xlsx";
                let headerLine = response.headers['content-disposition'];

                if (headerLine) {
                    let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                    if (token)
                        filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
                }

                this.$notify.success(`Загрузка файла "${filename}" завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let exportParams = await this.$refs.SelectExportParamsDlgRef.open({ exportType: 0 });

            if (exportParams.isCancelled === true)
                return;

            let request = httpAPI.post(`saylau/export/spreadsheet`, exportParams, { responseType: 'blob', skipErrorHandler: true });

            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_отчета"), { icons: { enabled: true } });
        },
        async ExportList() {
            let onResolve = (response) => {

                let filename = "Export.xlsx";
                let headerLine = response.headers['content-disposition'];

                if (headerLine) {
                    let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                    if (token)
                        filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
                }

                this.$notify.success(`Загрузка файла "${filename}" завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let request = httpAPI.post(`saylau/export/spreadsheet`, { Type: 6, ExportDate: null, Region: null }, { responseType: 'blob', skipErrorHandler: true });
            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_данных"), { icons: { enabled: true } });
        }
    },
    async mounted() {
        await this.loadData();
    }
}
</script>